// import React from 'react';
// import { motion } from 'framer-motion';

// import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
// import './Header.scss';

// const scaleVariants = {
//   whileInView: {
//     scale: [0, 1],
//     opacity: [0, 1],
//     transition: {
//       duration: 1,
//       ease: 'easeInOut',
//     },
//   },
// };

// const Header = () => (
//   <div className="app__header app__flex">
//     <motion.div
//       whileInView={{ x: [-100, 0], opacity: [0, 1] }}
//       transition={{ duration: 0.5 }}
//       className="app__header-info"
//     >
//       <div className="app__header-badge">
//         <div className="badge-cmp app__flex">
//           <span>👋</span>
//           <div style={{ marginLeft: 20 }}>
//             <p className="p-text">Hello, I am</p>
//             <h1 className="head-text">Pranay</h1>
//           </div>
//         </div>

//         <div className="tag-cmp app__flex">
//           <p className="p-text">Full Stack  Web Developer</p>
//           <p className="p-text">Freelancer</p>
         
//         </div>
       
//       </div>
//     </motion.div>

//     <motion.div
//       whileInView={{ opacity: [0, 1] }}
//       transition={{ duration: 0.5, delayChildren: 0.5 }}
//       className="app__header-img"
//     >
//       <img src={images.profile} alt="profile_bg" />
//       {/* <img src={images.p} alt="profile_bg" /> */}
//       <motion.img
//         whileInView={{ scale: [0, 1] }}
//         transition={{ duration: 1, ease: 'easeInOut' }}
//         src={images.circle}
//         alt="profile_circle"
//         className="overlay_circle"
//       />
//     </motion.div>

//     <motion.div
//       variants={scaleVariants}
//       whileInView={scaleVariants.whileInView}
//       className="app__header-circles"
//     >
//       {[images.react, images.redux, images.sass, images.node].map((circle, index) => (
//         <div className="circle-cmp app__flex" key={`circle-${index}`}>
//           <img src={circle} alt="profile_bg" />
//         </div>
//       ))}
//     </motion.div>
//   </div>
// );

// export default AppWrap(Header, 'home');


import React from 'react'

function Header() {
  return (
    <div className='p-2 min-h-screen pt-16'>
      <div class="sm:grid sm:grid-cols-2 sm:gap-16">

        <div class="sm:col-start-1 sm:col-end-2 pt-20 sm:mr-36">
          <div className='flex justify-end sm:pt-32'>
            
            <div>
            <div className='flex gap-1'>
          <span className='bg-green-500 rounded-[3px] text-[10px] p-1'>Front End Developer</span>
          <span className='bg-green-500 rounded-[3px] text-[10px] p-1'>Back End Developer</span>
          <span className='bg-blue-500 rounded-[3px] text-[10px] p-1'>Flutter Developer</span>

            </div>
            <span className='bg-red-500 rounded-[3px] text-[10px] p-1'>Freelancer</span>

              <h1 className='pt-2 text-[2.75rem] font-[800] capitalize'>Talk is cheap.</h1>
              <h1 className='text-[2.75rem] font-[800] capitalize'>Show me the code</h1>
              <br />
              <p className='opacity-70'>I design and code beautifully simple things,</p>
              <p className='opacity-70'>and i love what I do.</p>
              <br />
              <br />
              <a className='text-[#313bac] underline font-bold' href="mailto:pranaychavhan2102@gmail.com">LET'S CHAT!</a>
            </div>
          </div>

          <div className='flex flex-row sm:justify-center items-end gap-6 sm:gap-32 pt-36
           sm:pt-[220px]'>
            <div className='flex -flex-row items-center opacity-70 text-[12px] gap-1'>
              <h1 className='text-[2.75rem]'>2</h1>
              <p className=''>YEARS <br /> EXPERIENCE</p>
            </div>
            <div className='flex -flex-row items-center  opacity-70 text-[12px] gap-1'>
              <h1  className='text-[2.75rem]'>200+</h1>
              <p>QUESTIONS COMPLETED ON LEETCODE</p>
            </div>
          </div>
        </div>
        <div class="col-start-2 col-end-3 pt-24 hidden lg:block">

          <img src={images.header} alt="header vector" width={700} />
<div className='hidden 2xl:block'>


          <img src={images.flutter} alt="" width={60} className='absolute top-32 right-80'/>

          <img src={images.react} alt="" width={80} className='absolute top-[20rem] right-[9rem] '/>
          
          <img src={images.node} alt="" width={80} className='absolute top-[25rem] right-[14rem] '/>


          <img src={images.laravel} alt="" width={60} className='absolute top-[24rem] right-[45rem] '/>


          <img src={images.docker} alt="" width={70} className='absolute top-[8rem] right-[43rem] '/>


          <img src={images.java} alt="" width={80} className='absolute top-[14rem] right-[49rem] '/>


          <img src={images.sass} alt="" width={80} className='absolute top-[35rem] right-[15rem] '/>


          <img src={images.git} alt="" width={80} className='absolute top-[27rem] right-[5rem] '/>
          </div>
        </div>
    </div>
  </div>
  )
}

export default Header

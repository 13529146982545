import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/PranayChavhan_" target="blank"><BsTwitter /></a>
    </div>
    <div>
      <a href="https://www.linkedin.com/in/pranay-chavhan-38785a224/" target="blank"><BsLinkedin /></a>
    </div>
    <div>
      <a href="https://github.com/PranayChavhan" target="blank"><BsGithub /></a>
    </div>
    <div>
      <a href="https://www.instagram.com/p.r.a.n.a.y_chavhan/" target="blank"><BsInstagram /></a>
    </div>
    <div>
      <a href="https://www.showwcase.com/pranaychavhan" target="blank"><CgWebsite /></a>
    </div>
  </div>
);

export default SocialMedia;

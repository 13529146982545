import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">I Know that <span>Good Design</span> <br />means  <span>Good Business</span></h2>



      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 3 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>

      <h1 className='text-[24px] font-bold'>About me</h1>
      <div className='about-container'>

        <p >
          Hey 👋🏻, I am <b>PRANAY</b>, a passionate full-stack developer who likes to turn ideas into code and build fun stuff! <br />

          Developed a passion for coding in high school, I started my journey with C++ and today after more than 4 years, I am a <b>MERN Stack Developer</b> with the same passion and dedication to solve problems and to code.

          I like to network with people and increase my experience. <br /> <i>I believe that experiences are gathered when one steps out of their comfort zones otherwise, it's just a single year of experience done multiple times.</i>
          <br /> I'm currently building some cool and useful products that address unique problems.

          <br /> <b>My recent builds:</b>

          <ol>
            <li><a href="https://github.com/PranayChavhan/Face_recognition_based_attendance">Face_recognition_based_attendance</a></li>
            <li><a href="https://github.com/PranayChavhan/Google-Keep-Clone">Google-Keep-Clone</a></li>
            <li><a href="https://github.com/PranayChavhan/News-TV">News-TV</a></li>
            <li><a href="https://github.com/PranayChavhan/Twitter-Clone"> Twitter-Clone</a></li>
            <li><a href="https://github.com/PranayChavhan/Disney-Plus-Clone">Disney-Plus-Clone</a></li>
          </ol>



          You can follow me on <a href="https://twitter.com/PranayChavhan_">Twitter</a> , <a href="https://www.linkedin.com/in/pranay-chavhan-38785a224/">LinkedIn</a>, and of course <a href="https://www.showwcase.com/pranaychavhan">Showwcase</a>.
        </p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
